<template>
  <CRow>
    <CCol col="12" xl="12">
      <Spinner v-if="$store.state.loadSpinner" />

      <CCard v-else>
        <CCardHeader> العملاء </CCardHeader>
        <CCardBody>
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mb-5">
              <thead>
                <tr class="table-primary">
                  <th scope="col">#</th>
                  <th scope="col">الاسم</th>
                  <th scope="col">الموبايل</th>
                  <th scope="col">الايميل</th>
                  <th scope="col">الواتس</th>
                  <th scope="col">المحفظة</th>
                  <th scope="col">الصورة</th>
                  <th scope="col">الحالة</th>
                  <th scope="col">الاجراءات</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <th scope="row">{{ item.id }}</th>
                  <td>{{ item.name }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.whatsapp_number }}</td>
                  <td>{{ item.wallet }}</td>
                  <td>
                    <CImg
                      height="80px"
                      width="80px"
                      :src="item.image_url"
                      fluid
                    />
                  </td>
                  <td>
                    <b :class="`text-${getBadge(item.status)}`">
                      {{ item.status == "1" ? "نشط" : "متوقف" }}
                    </b>
                  </td>
                  <td>
                    --
                    <!-- <CButton
                      color="info"
                      @click="
                        $router.push({
                          path: `clients/${item.id}`,
                          params: { client: item },
                        })
                      "
                    >
                      <CIcon name="cil-low-vision"></CIcon>
                    </CButton> -->
                    <!-- <CButton
                      class="mx-1"
                      color="danger"
                      @click="stopClient(item.id)"
                    >
                      <CIcon name="cil-power-standby"></CIcon>
                    </CButton> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <CPagination :active-page.sync="currentPage" :pages="totalPages" />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "Clients",
  data() {
    return {
      items: [],
      activePage: 1,
      currentPage: 1,
      allPages: 10,
    };
  },
  watch: {
    currentPage: {
      handler: function (val, oldVal) {
        this.fetchData(val);
      },
      deep: true,
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData(page = 1) {
      this.toggleLoadSpinner();

      this.$http
        .get(`clients?page=${page}`)
        .then((res) => {
          // handle success
          this.items = res.data.data.data;
          this.totalPages = res.data.data.last_page;
          this.toggleLoadSpinner();
        })
        .catch((error) => {
          //
          this.toggleLoadSpinner();
        });
    },

    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    },

    stopClient(itemId) {
      console.log(itemId);
    },

    getBadge(status) {
      switch (status) {
        case "active":
          return "success";
        case "inactive":
          return "secondary";
        case "Pending":
          return "warning";
        case "Banned":
          return "danger";
        default:
          "primary";
      }
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
};
</script>